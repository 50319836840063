<template>
  <div>
    <van-nav-bar left-arrow title="预览" fixed @click-left="$router.go(-1)" />
    <div class="content">
      <h1>开发中</h1>
      <iframe :src='fileUrl' width='100%' height='100%' frameborder='0'>
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "fileView",
  data(){
    return {
      fileUrl: ''
    };
  },
  methods: {
    getFileType(fileName){
      var index= fileName.lastIndexOf(".");
      //获取后缀
      return fileName.substr(index+1);
    },
    handleFileView (id, name) {
      // 后台返回流的形式
      let url = process.env.VUE_APP_API_HOST + '/microarch/sys/sysAttchement/showPdf/' + id +'.' + this.getFileType(name);
      return 'http://127.0.0.1:8012/onlinePreview?url=' + encodeURIComponent(window.btoa(url));
    },
  },
  mounted() {
    debugger;
    this.fileUrl = this.handleFileView(this.$route.query.id, this.$route.query.filename)
  }
}
</script>

<style scoped>
.content {
  margin-top: 47px;
}
</style>
